var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "text-center" }, [
    _c("h2", { staticClass: "e-warning" }, [_vm._v("404")]),
    _c("h3", [_vm._v(_vm._s(_vm.$t("404.pageNotFound")))]),
    _c("p", [
      _vm._v(" " + _vm._s(_vm.$t("404.meanwhileYouMay")) + " "),
      _c("a", { on: { click: _vm.returnBack } }, [
        _vm._v(_vm._s(_vm.$t("404.returnBack")))
      ]),
      _vm._v(" " + _vm._s(_vm.$t("404.or")) + " "),
      _c("a", { on: { click: _vm.returnToDashboard } }, [
        _vm._v(_vm._s(_vm.$t("404.returnToDashboard")))
      ]),
      _vm._v(". ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }